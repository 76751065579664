import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

// Components
import { Spinner } from '@lionstep/ui';
import JobForm from '../../components/JobForm/JobForm';
import PublishConfirmModal from '../../components/PublishConfirm/PublishConfirmModal';

// Hooks
import useIsLionstepAdmin from '../../../../hooks/useIsLionstepAdmin';

// helpers
import { languageShorthandToOriginalFull } from '../../../../support';
import {
  jobMapperInput,
  jobMapperOutput,
} from '../../components/JobForm/job.helper';

// actions
import { createJob } from '../../redux/actions';

// selectors
import { companySelector } from '../../../company/redux/selectors/company.selectors';
import { isLoadingJobSelector } from '../../redux/selectors/job.selectors';

// Constants
import { JOB_STATUSES, DEFAULT_SEARCHER_INFO } from '../../job.constants';

// Queries
import { useUserQuery } from '../../../../queries/userQueries';
import {
  useCompanyKomboIntegrationQuery,
  useCompanyKomboJobsQuery,
} from '../../../../queries/companyQueries';

// schema
import {
  publishJobSchema,
  draftJobSchema,
} from '../../components/JobForm/job.schema';

const JOB_BASE_SALARY_MIN = "10'000";
const JOB_ADDITIONAL_BONUS = 1; // 0 -> yes, 1 -> no
const JOB_CURRENCY = 'EUR';

const CreateJob = () => {
  const { i18n, t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const company = useSelector(companySelector);
  const isLoadingJob = useSelector(isLoadingJobSelector);
  const isLionstepAdmin = useIsLionstepAdmin();

  // Queries
  const { data: user } = useUserQuery();
  const { data: komboIntegration, isFetching: isLoadingKomboIntegration } =
    useCompanyKomboIntegrationQuery(
      {
        id: user.company_id,
      },
      {
        enabled: company?.enable_kombo_integration,
      },
    );
  const { refetch: getKomboJobs } = useCompanyKomboJobsQuery(
    {
      id: user.company_id,
    },
    {
      enabled: company?.enable_kombo_integration,
    },
  );

  const [formData, setFormData] = useState(null);
  const [showConfirmPublish, setShowConfirmPublish] = useState(false);

  useEffect(() => {
    if (!isLionstepAdmin) {
      history.push('/');
    }
  }, []);

  useEffect(() => {
    if (company && komboIntegration && komboIntegration.state === 'ACTIVE') {
      getKomboJobs();
    }
  }, [company, komboIntegration]);

  const newJob = {
    // General
    original_url: '', // --> extra_info
    job_file: null, // --> extra_info
    job_language: {
      mid: languageShorthandToOriginalFull(i18n.language),
    }, // --> job
    job_video: '', // --> extra_info
    job_video_caption: '', // --> extra_info
    introduction: '', // -> new field // --> extra_info
    message_template: [],
    kombo_job_id: null,

    // Summary
    opening_title: '', // --> job
    summary: '', // -> merged real_desc & goals // --> extra_info
    your_role: '', // your role // --> extra_info
    requirements: '', // Must have // --> extra_info
    nice_to_have: '', // --> extra_info
    talent_languages: [], // --> job
    benefits_checked: [
      ...(company?.benefits_pool_selected ?? []),
      ...(company?.benefits_custom
        ?.filter((b) => !b.deleted)
        .map((b) => b.id) ?? []),
    ],
    additional_benefits: '',
    can_be_disclosed: company?.can_be_disclosed ?? true, // --> extra_info
    replacement_name: company?.replacement_name ?? '', // --> extra_info
    approach: null,
    diversity: false, // --> extra_info
    job_location: null, // --> job
    additional_locations: [],
    contract_type: null, // --> extra_info
    start_date: null, // --> extra_info
    end_date: null, // --> extra_info
    employment_type: 'fulltime', // --> extra_info
    employment_type_max: null, // --> extra_info
    employment_type_min: null, // --> extra_info
    leadership_experience: false, // --> extra_info
    leadership_importance: null, // --> extra_info
    management_role: false, // -> new field // --> extra_info
    peopleToManage: null, // direct reports/ / --> extra_info
    travel: false, // --> extra_info
    travel_importance: null, // --> extra_info
    travel_details: '', // --> extra_info
    freelance: false, // --> extra_info
    homeoffice: false, // --> extra_info
    home_days: null, // --> extra_info

    // Team
    team_desc: '', // --> extra_info
    superior_role: '', // --> extra_info
    team_language: '', // --> extra_info
    team_size: '', // --> extra_info

    // Meta
    contact_person: '', // --> extra_info
    contact_person_title: '', // --> extra_info
    experience: [], // used in min_experience and max_experience
    skill_groups: [], // --> job (part of skill_groups) - contains both optional_skills and mandatory_skills_groups
    free_question: '', // --> job (free_question)
    titles: [], // --> job
    locations: [],
  };

  const handleOnDraftSave = (event, data) => {
    const { extraInfo, job } = jobMapperOutput(data || formData);

    // Default values for new job
    extraInfo.additional_bonus = JOB_ADDITIONAL_BONUS;
    extraInfo.bonus = extraInfo.bonus || '';
    extraInfo.base_salary_min =
      extraInfo.base_salary_min || JOB_BASE_SALARY_MIN;
    extraInfo.currency = extraInfo.currency || JOB_CURRENCY;
    job.searcher_info = DEFAULT_SEARCHER_INFO;
    job.competitors_list = company?.competitors_list ?? '';
    job.preferred_companies = company?.preferred_companies ?? '';
    job.number_of_positions = 1;
    job.publish_to_job_board = true;

    dispatch(createJob({ job, extraInfo, isPublishing: false, history, user }));
    setShowConfirmPublish(false);
  };

  const handleOnPublish = () => {
    const { extraInfo, job } = jobMapperOutput(formData);

    // Default values for new job
    extraInfo.additional_bonus = JOB_ADDITIONAL_BONUS;
    extraInfo.bonus = extraInfo.bonus || '';
    extraInfo.base_salary_min =
      extraInfo.base_salary_min || JOB_BASE_SALARY_MIN;
    extraInfo.currency = extraInfo.currency || JOB_CURRENCY;
    job.searcher_info = DEFAULT_SEARCHER_INFO;
    job.competitors_list = company?.competitors_list ?? '';
    job.preferred_companies = company?.preferred_companies ?? '';
    job.number_of_positions = 1;
    job.status = JOB_STATUSES.jobDescriptionDone;
    job.publish_to_job_board = true;

    dispatch(createJob({ job, extraInfo, isPublishing: true, history, user }));
    setShowConfirmPublish(false);
  };

  const onSubmit = ({ data, shouldPublish = false }) => {
    setFormData(data);

    if (!shouldPublish) {
      handleOnDraftSave(null, data);
    } else {
      setShowConfirmPublish(true);
    }
  };

  return (
    <>
      {(isLoadingJob || isLoadingKomboIntegration) && (
        <Spinner position="fixed" size={100} />
      )}

      {company && (
        <JobForm
          job={jobMapperInput(newJob, t)}
          company={company}
          publishJobSchema={publishJobSchema}
          draftJobSchema={draftJobSchema}
          onSubmit={onSubmit}
        />
      )}

      <PublishConfirmModal
        show={showConfirmPublish}
        onCancel={handleOnDraftSave}
        onConfirm={handleOnPublish}
      />
    </>
  );
};

export default CreateJob;
