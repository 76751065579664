import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

// Components
import { Spinner } from '@lionstep/ui';

// actions
import { updateJob } from '../../redux/actions/job.actions';

// selectors
import { companySelector } from '../../../company/redux/selectors/company.selectors';
import {
  jobSelector,
  isLoadingJobSelector,
  extraInfoSelector,
  isLoadingExtraInfoSelector,
} from '../../redux/selectors/job.selectors';

// helpers
import {
  jobMapperInput,
  jobMapperOutput,
} from '../../components/JobForm/job.helper';

// Components
import JobForm from '../../components/JobForm/JobForm';
import PublishConfirmModal from '../../components/PublishConfirm/PublishConfirmModal';

// Queries
import { useUserQuery } from '../../../../queries/userQueries';
import {
  useCompanyKomboIntegrationQuery,
  useCompanyKomboJobsQuery,
} from '../../../../queries/companyQueries';

// schema
import {
  publishJobSchema,
  draftJobSchema,
} from '../../components/JobForm/job.schema';
import { JOB_STATUSES } from '../../job.constants';

const EditJob = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [formData, setFormData] = useState(null);
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const [showConfirmPublish, setShowConfirmPublish] = useState(false);

  const currentJob = useSelector(jobSelector);
  const currentExtraInfo = useSelector(extraInfoSelector);
  const isLoadingJob = useSelector(isLoadingJobSelector);
  const isLoadingExtraInfo = useSelector(isLoadingExtraInfoSelector);
  const company = useSelector(companySelector);
  const { data: user } = useUserQuery();
  const { data: komboIntegration, isFetching: isLoadingKomboIntegration } =
    useCompanyKomboIntegrationQuery(
      {
        id: user.company_id,
      },
      {
        enabled: company?.enable_kombo_integration,
      },
    );
  const { refetch: getKomboJobs } = useCompanyKomboJobsQuery({
    id: user.company_id,
    options: {
      enabled: company?.enable_kombo_integration,
    },
  });

  useEffect(() => {
    if (company && komboIntegration && komboIntegration.state === 'ACTIVE') {
      getKomboJobs();
    }
  }, [company, komboIntegration]);

  const editJob = {
    ...currentJob,
    ...currentExtraInfo,
    benefits_checked: [
      ...(currentJob?.benefits_pool_selected ?? []),
      ...(currentJob?.benefits_custom_selected ?? []),
    ],
  };

  const handleOnDraftSaveOrUpdate = (event, data, redirect) => {
    const { extraInfo, job } = jobMapperOutput(data || formData);

    dispatch(
      updateJob({
        id: currentJob.id,
        job,
        extraInfo,
        isPublishing: false,
        shouldRedirect: redirect || shouldRedirect,
        slug: currentJob.slug,
        history,
        user,
      }),
    );
    setShowConfirmPublish(false);
  };

  const handleOnPublish = () => {
    const { extraInfo, job } = jobMapperOutput(formData);

    if (!currentJob.status) {
      job.status = JOB_STATUSES.jobDescriptionDone;
    }
    dispatch(
      updateJob({
        id: currentJob.id,
        job,
        extraInfo,
        isPublishing: true,
        slug: currentJob.slug,
        history,
        user,
      }),
    );
    setShowConfirmPublish(false);
  };

  const onSubmit = ({
    data,
    shouldPublish = false,
    shouldRedirect: redirect = true,
  }) => {
    setFormData(data);
    setShouldRedirect(redirect);

    if (!shouldPublish) {
      handleOnDraftSaveOrUpdate(null, data, redirect);
    } else {
      setShowConfirmPublish(true);
    }
  };

  const isLoading =
    isLoadingJob || isLoadingExtraInfo || isLoadingKomboIntegration;
  const shouldRenderForm = company && currentJob && currentExtraInfo;

  return (
    <>
      <Helmet>
        <title>{t('edit_job')} | Lionstep</title>
      </Helmet>

      {isLoading && <Spinner position="fixed" size={100} />}

      {shouldRenderForm && (
        <JobForm
          job={jobMapperInput(editJob, t)}
          company={company}
          publishJobSchema={publishJobSchema}
          draftJobSchema={draftJobSchema}
          onSubmit={onSubmit}
        />
      )}

      <PublishConfirmModal
        show={showConfirmPublish}
        onCancel={handleOnDraftSaveOrUpdate}
        onConfirm={handleOnPublish}
      />
    </>
  );
};

export default EditJob;
