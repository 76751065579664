import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Grid, Spinner } from '@lionstep/ui';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

// Actions
import {
  getCreditsTotal,
  getCreditsHistory,
} from '../../redux/actions/credits.actions';

// Selectors
import { totalCreditsSelector } from '../../redux/selectors/credits.selectors';

// Hooks
import useIsLionstepAdmin from '../../../../hooks/useIsLionstepAdmin';

// Queries
import { useCompanyPlanQuery } from '../../../../queries/companyQueries';
import { useUserQuery } from '../../../../queries/userQueries';

// Constants
import { USER_ROLES } from '../../../../constants/user.const';

// Components
import ChargeCreditsCard from './components/ChargeCreditsCard';
import CreditsHistoryCard from './components/CreditsHistoryCard';
import TotalCreditsCard from './components/TotalCreditsCard';
import PageHeading from '../../../../components/refactoring/pageHeading/PageHeading';

// Styles
import styles from './credits.module.scss';

const Credits = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { data: user } = useUserQuery();
  const isLionstepAdmin = useIsLionstepAdmin();
  const companyPlan = useCompanyPlanQuery();
  const totalCredits = useSelector(totalCreditsSelector);

  useEffect(() => {
    dispatch(getCreditsTotal({ companyId: user.company_id }));
    dispatch(getCreditsHistory({ companyId: user.company_id }));
  }, []);

  useEffect(() => {
    if (user?.role === USER_ROLES.viewer) {
      history.push('/');
    }
  }, [user]);

  if (companyPlan.isLoading) {
    return <Spinner position="absolute" size={100} />;
  }

  return (
    <Grid.Container className={styles.credits}>
      <Helmet>
        <title>{t('credits_page.title')} | Lionstep</title>
      </Helmet>

      <Grid.Row gutter={30}>
        <Grid.Col
          xs={24}
          xl={{ span: 22, offset: 1 }}
          xxl={{ span: 18, offset: 3 }}
        >
          <PageHeading>{t('credits_page.title')}</PageHeading>
        </Grid.Col>
      </Grid.Row>

      <Grid.Row gutter={[30, 30]}>
        <Grid.Col
          xs={24}
          xl={{ span: 22, offset: 1 }}
          xxl={{ span: 18, offset: 3 }}
        >
          <TotalCreditsCard />
        </Grid.Col>
      </Grid.Row>

      {isLionstepAdmin && totalCredits > 0 && (
        <Grid.Row gutter={[30, 30]}>
          <Grid.Col
            xs={24}
            xl={{ span: 22, offset: 1 }}
            xxl={{ span: 18, offset: 3 }}
          >
            <ChargeCreditsCard />
          </Grid.Col>
        </Grid.Row>
      )}

      <Grid.Row gutter={[30, 30]}>
        <Grid.Col
          xs={24}
          xl={{ span: 22, offset: 1 }}
          xxl={{ span: 18, offset: 3 }}
        >
          <CreditsHistoryCard />
        </Grid.Col>
      </Grid.Row>
    </Grid.Container>
  );
};

export default Credits;
